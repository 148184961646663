var exports = {};

// https://d3js.org/d3-ease/ Version 1.0.3. Copyright 2017 Mike Bostock.
(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict';

  function linear(t) {
    return +t;
  }

  function quadIn(t) {
    return t * t;
  }

  function quadOut(t) {
    return t * (2 - t);
  }

  function quadInOut(t) {
    return ((t *= 2) <= 1 ? t * t : --t * (2 - t) + 1) / 2;
  }

  function cubicIn(t) {
    return t * t * t;
  }

  function cubicOut(t) {
    return --t * t * t + 1;
  }

  function cubicInOut(t) {
    return ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2;
  }

  var exponent = 3;

  var polyIn = function custom(e) {
    e = +e;

    function polyIn(t) {
      return Math.pow(t, e);
    }

    polyIn.exponent = custom;
    return polyIn;
  }(exponent);

  var polyOut = function custom(e) {
    e = +e;

    function polyOut(t) {
      return 1 - Math.pow(1 - t, e);
    }

    polyOut.exponent = custom;
    return polyOut;
  }(exponent);

  var polyInOut = function custom(e) {
    e = +e;

    function polyInOut(t) {
      return ((t *= 2) <= 1 ? Math.pow(t, e) : 2 - Math.pow(2 - t, e)) / 2;
    }

    polyInOut.exponent = custom;
    return polyInOut;
  }(exponent);

  var pi = Math.PI;
  var halfPi = pi / 2;

  function sinIn(t) {
    return 1 - Math.cos(t * halfPi);
  }

  function sinOut(t) {
    return Math.sin(t * halfPi);
  }

  function sinInOut(t) {
    return (1 - Math.cos(pi * t)) / 2;
  }

  function expIn(t) {
    return Math.pow(2, 10 * t - 10);
  }

  function expOut(t) {
    return 1 - Math.pow(2, -10 * t);
  }

  function expInOut(t) {
    return ((t *= 2) <= 1 ? Math.pow(2, 10 * t - 10) : 2 - Math.pow(2, 10 - 10 * t)) / 2;
  }

  function circleIn(t) {
    return 1 - Math.sqrt(1 - t * t);
  }

  function circleOut(t) {
    return Math.sqrt(1 - --t * t);
  }

  function circleInOut(t) {
    return ((t *= 2) <= 1 ? 1 - Math.sqrt(1 - t * t) : Math.sqrt(1 - (t -= 2) * t) + 1) / 2;
  }

  var b1 = 4 / 11;
  var b2 = 6 / 11;
  var b3 = 8 / 11;
  var b4 = 3 / 4;
  var b5 = 9 / 11;
  var b6 = 10 / 11;
  var b7 = 15 / 16;
  var b8 = 21 / 22;
  var b9 = 63 / 64;
  var b0 = 1 / b1 / b1;

  function bounceIn(t) {
    return 1 - bounceOut(1 - t);
  }

  function bounceOut(t) {
    return (t = +t) < b1 ? b0 * t * t : t < b3 ? b0 * (t -= b2) * t + b4 : t < b6 ? b0 * (t -= b5) * t + b7 : b0 * (t -= b8) * t + b9;
  }

  function bounceInOut(t) {
    return ((t *= 2) <= 1 ? 1 - bounceOut(1 - t) : bounceOut(t - 1) + 1) / 2;
  }

  var overshoot = 1.70158;

  var backIn = function custom(s) {
    s = +s;

    function backIn(t) {
      return t * t * ((s + 1) * t - s);
    }

    backIn.overshoot = custom;
    return backIn;
  }(overshoot);

  var backOut = function custom(s) {
    s = +s;

    function backOut(t) {
      return --t * t * ((s + 1) * t + s) + 1;
    }

    backOut.overshoot = custom;
    return backOut;
  }(overshoot);

  var backInOut = function custom(s) {
    s = +s;

    function backInOut(t) {
      return ((t *= 2) < 1 ? t * t * ((s + 1) * t - s) : (t -= 2) * t * ((s + 1) * t + s) + 2) / 2;
    }

    backInOut.overshoot = custom;
    return backInOut;
  }(overshoot);

  var tau = 2 * Math.PI;
  var amplitude = 1;
  var period = 0.3;

  var elasticIn = function custom(a, p) {
    var s = Math.asin(1 / (a = Math.max(1, a))) * (p /= tau);

    function elasticIn(t) {
      return a * Math.pow(2, 10 * --t) * Math.sin((s - t) / p);
    }

    elasticIn.amplitude = function (a) {
      return custom(a, p * tau);
    };

    elasticIn.period = function (p) {
      return custom(a, p);
    };

    return elasticIn;
  }(amplitude, period);

  var elasticOut = function custom(a, p) {
    var s = Math.asin(1 / (a = Math.max(1, a))) * (p /= tau);

    function elasticOut(t) {
      return 1 - a * Math.pow(2, -10 * (t = +t)) * Math.sin((t + s) / p);
    }

    elasticOut.amplitude = function (a) {
      return custom(a, p * tau);
    };

    elasticOut.period = function (p) {
      return custom(a, p);
    };

    return elasticOut;
  }(amplitude, period);

  var elasticInOut = function custom(a, p) {
    var s = Math.asin(1 / (a = Math.max(1, a))) * (p /= tau);

    function elasticInOut(t) {
      return ((t = t * 2 - 1) < 0 ? a * Math.pow(2, 10 * t) * Math.sin((s - t) / p) : 2 - a * Math.pow(2, -10 * t) * Math.sin((s + t) / p)) / 2;
    }

    elasticInOut.amplitude = function (a) {
      return custom(a, p * tau);
    };

    elasticInOut.period = function (p) {
      return custom(a, p);
    };

    return elasticInOut;
  }(amplitude, period);

  exports.easeLinear = linear;
  exports.easeQuad = quadInOut;
  exports.easeQuadIn = quadIn;
  exports.easeQuadOut = quadOut;
  exports.easeQuadInOut = quadInOut;
  exports.easeCubic = cubicInOut;
  exports.easeCubicIn = cubicIn;
  exports.easeCubicOut = cubicOut;
  exports.easeCubicInOut = cubicInOut;
  exports.easePoly = polyInOut;
  exports.easePolyIn = polyIn;
  exports.easePolyOut = polyOut;
  exports.easePolyInOut = polyInOut;
  exports.easeSin = sinInOut;
  exports.easeSinIn = sinIn;
  exports.easeSinOut = sinOut;
  exports.easeSinInOut = sinInOut;
  exports.easeExp = expInOut;
  exports.easeExpIn = expIn;
  exports.easeExpOut = expOut;
  exports.easeExpInOut = expInOut;
  exports.easeCircle = circleInOut;
  exports.easeCircleIn = circleIn;
  exports.easeCircleOut = circleOut;
  exports.easeCircleInOut = circleInOut;
  exports.easeBounce = bounceOut;
  exports.easeBounceIn = bounceIn;
  exports.easeBounceOut = bounceOut;
  exports.easeBounceInOut = bounceInOut;
  exports.easeBack = backInOut;
  exports.easeBackIn = backIn;
  exports.easeBackOut = backOut;
  exports.easeBackInOut = backInOut;
  exports.easeElastic = elasticOut;
  exports.easeElasticIn = elasticIn;
  exports.easeElasticOut = elasticOut;
  exports.easeElasticInOut = elasticInOut;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const easeLinear = exports.easeLinear,
      easeQuad = exports.easeQuad,
      easeQuadIn = exports.easeQuadIn,
      easeQuadOut = exports.easeQuadOut,
      easeQuadInOut = exports.easeQuadInOut,
      easeCubic = exports.easeCubic,
      easeCubicIn = exports.easeCubicIn,
      easeCubicOut = exports.easeCubicOut,
      easeCubicInOut = exports.easeCubicInOut,
      easePoly = exports.easePoly,
      easePolyIn = exports.easePolyIn,
      easePolyOut = exports.easePolyOut,
      easePolyInOut = exports.easePolyInOut,
      easeSin = exports.easeSin,
      easeSinIn = exports.easeSinIn,
      easeSinOut = exports.easeSinOut,
      easeSinInOut = exports.easeSinInOut,
      easeExp = exports.easeExp,
      easeExpIn = exports.easeExpIn,
      easeExpOut = exports.easeExpOut,
      easeExpInOut = exports.easeExpInOut,
      easeCircle = exports.easeCircle,
      easeCircleIn = exports.easeCircleIn,
      easeCircleOut = exports.easeCircleOut,
      easeCircleInOut = exports.easeCircleInOut,
      easeBounce = exports.easeBounce,
      easeBounceIn = exports.easeBounceIn,
      easeBounceOut = exports.easeBounceOut,
      easeBounceInOut = exports.easeBounceInOut,
      easeBack = exports.easeBack,
      easeBackIn = exports.easeBackIn,
      easeBackOut = exports.easeBackOut,
      easeBackInOut = exports.easeBackInOut,
      easeElastic = exports.easeElastic,
      easeElasticIn = exports.easeElasticIn,
      easeElasticOut = exports.easeElasticOut,
      easeElasticInOut = exports.easeElasticInOut,
      __esModule = exports.__esModule;